const questions = {
  1: {
    id: 1,
    question:
      'During October, _______ ribbons are featured to raise breast cancer awareness',
    answer: 'pink',
  },
  2: {
    id: 2,
    question: 'A popular Australian Cattle Dog is called the _______ Heeler',
    answer: 'blue',
  },
  3: {
    id: 3,
    question:
      'Known as the _______ city, Jaipur is found in the Asian country of India',
    answer: 'pink',
  },
  4: {
    id: 4,
    question: "In Danish, 'lyserød' is the word for _______",
    answer: 'pink',
  },
  5: {
    id: 5,
    question: 'In Thailand, Friday is associated with the color _______',
    answer: 'blue',
  },
  6: {
    id: 6,
    question:
      'The India International Cricket Team plays their matches in a _______ shirt',
    answer: 'blue',
  },
  7: {
    id: 7,
    question:
      'Gwyneth Paltrow won an Oscar in 1999 while wearing a _______ dress',
    answer: 'pink',
  },
  8: {
    id: 8,
    question:
      'In 1999, Eiffel 65 scored a number 1 hit with their song called ________',
    answer: 'blue',
  },
  9: {
    id: 9,
    question:
      'Pentonville Road is the color _______ on a standard London Monopoly board',
    answer: 'blue',
  },
  10: {
    id: 10,
    question:
      'A round, _______ creature was first released in 1992 as the protagonist of a Nintendo game series',
    answer: 'pink',
  },
  11: {
    id: 11,
    question: 'Beyonce’s daughter’s name is _______ Ivy',
    answer: 'blue',
  },
  12: {
    id: 12,
    question:
      'In the film ‘Bridget Jones’ Diary,’ a dinner guest is served _______ soup',
    answer: 'blue',
  },
  13: {
    id: 13,
    question:
      'Mountbatten _______ was a camouflage color first used by the British Royal Navy in World War II',
    answer: 'pink',
  },
  14: {
    id: 14,
    question: 'The Owl is the only bird that can see the color _______',
    answer: 'blue',
  },
  15: {
    id: 15,
    question:
      'In the ‘Harry Potter’ series, the Defense Against the Dark Arts professor, Dolores Umbridge, primarily wears _______',
    answer: 'pink',
  },
  16: {
    id: 16,
    question: 'John Hughes wrote the popular teen comedy, ‘Pretty in _______',
    answer: 'pink',
  },
}

export default questions
