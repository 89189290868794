/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { motion, useSpring } from 'framer-motion'

import SEO from '../components/seo'
import Question from '../components/question'

import questions from '../../content/assets/questions'

const getRandomInt = () => {
  return Math.floor(Math.random() * Math.floor(6))
}

const Hard = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const possibleButtonFailureTexts = [
    'Sorry, try again...',
    "Humm, that's not quite it",
    'Oh no...',
    "Don't give up!",
    'No, but you can do this!',
    "Don't give up...",
  ]

  const [answers, setAnswers] = useState({})
  const [buttonText, setButtonText] = useState('Check Answers')
  const [showNumberCorrect, setShowNumberCorrect] = useState(false)
  const [numberCorrect, setNumberCorrect] = useState(0)

  const markSelected = (id, selected) => {
    const newAnswers = answers
    newAnswers[id] = selected
    setAnswers(newAnswers)
  }

  const checkAnswers = e => {
    e.preventDefault()
    let numCorrect = 0

    for (const [id, question] of Object.entries(questions)) {
      if (id in answers) {
        if (answers[id] === question.answer) {
          numCorrect = numCorrect + 1
        }
      }
    }

    if (numCorrect === Object.keys(questions).length) {
      navigate('/lol')
    } else {
      setNumberCorrect(numCorrect)
      setShowNumberCorrect(true)
      setButtonText(possibleButtonFailureTexts[getRandomInt()])
    }
  }

  return (
    <div className="w-full bg-gray-900 text-white">
      <SEO title={siteTitle} keywords={[`baby`, `boy`, `girl`, `longhurst`]} />
      <div className="mx-auto w-auto md:w-1/2">
        <h1 className="pt-12 md:pt-20 text-center font-bold text-5xl md:text-6xl uppercase">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-blue-500">
            Pink or Blue
          </span>
        </h1>
        <p className="px-6 mt-2 text-xl text-center">
          Answer all of the questions, then check your answers to proceed
        </p>
      </div>
      <form
        className="mx-auto mt-8 md:mt-14 px-6 pb-24 md:pb-44 md:w-1/2 flex flex-col justify-center"
        onSubmit={checkAnswers}
      >
        {Object.keys(questions).map(id => {
          return (
            <Question
              key={id}
              question={questions[id]}
              markSelected={markSelected}
            />
          )
        })}
        <button
          type="submit"
          className="mx-auto w-auto inline-flex items-center px-6 py-3 border text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-blue-400 to-pink-400 hover:via-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {buttonText}
        </button>
        {showNumberCorrect ? (
          <p className="mx-auto w-auto inline-flex items-center text-white px-6 py-3">
            You have {numberCorrect} out of 16 correct
          </p>
        ) : null}
      </form>
    </div>
  )
}

export default Hard

export const hardPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
