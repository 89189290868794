/* eslint-disable react/prop-types */
import React, { useState } from 'react'

const blueClassList = {
  selected:
    'relative inline-flex items-center px-4 py-2 rounded-l-full bg-blue-500 text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-50 focus:border-blue-50',
  notSelected:
    'relative inline-flex items-center px-4 py-2 rounded-l-full bg-white text-sm font-medium text-blue-500 hover:bg-blue-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
}

const pinkClassList = {
  selected:
    '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-full bg-pink-400 text-white text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-pink-50 focus:border-pink-50',
  notSelected:
    '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-full bg-white text-sm font-medium text-pink-400 hover:bg-pink-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-pink-400 focus:border-pink-400',
}

const Question = ({ question, markSelected }) => {
  const [pinkSelected, setPinkSelected] = useState(false)
  const [blueSelected, setBlueSelected] = useState(false)

  const questionLabel = `question-number-${question.id}`

  const onClick = e => {
    if (e.target.id === 'pink') {
      markSelected(question.id, 'pink')
      setPinkSelected(true)
      setBlueSelected(false)
    } else {
      markSelected(question.id, 'blue')
      setBlueSelected(true)
      setPinkSelected(false)
    }
  }

  return (
    <div className="pb-10 flex justify-between content-center">
      <p className="text-white text-md md:text-xl flex-1">
        {question.id}. {question.question}
      </p>
      <fieldset>
        <legend id={questionLabel} className="sr-only">
          Question Number {question.id}
        </legend>
        <ul
          className="relative ml-2 md:ml-4 z-0 inline-flex shadow-sm rounded-full"
          role="radiogroup"
          aria-labelledby={questionLabel}
        >
          <li
            id="blue"
            tabIndex="0"
            role="radio"
            aria-checked={blueSelected}
            className={
              blueSelected ? blueClassList.selected : blueClassList.notSelected
            }
            onClick={onClick}
          >
            Blue
          </li>
          <li
            id="pink"
            tabIndex="1"
            role="radio"
            aria-checked={pinkSelected}
            className={
              pinkSelected ? pinkClassList.selected : pinkClassList.notSelected
            }
            onClick={onClick}
          >
            Pink
          </li>
        </ul>
      </fieldset>
    </div>
  )
}

export default Question
